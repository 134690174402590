<template>
  <div>
    <div class="tw-relative">
      <input
        ref="input"
        v-model="inputValue"
        v-bind="$attrs"
        class="form-control"
      />
      <div
        class="tw-top-0 tw-right-0 tw-absolute tw-flex tw-h-full tw-justify-end tw-items-center"
      >
        <div
          :class="[
            isPasswordSecure ? 'tw-bg-green-600' : '',
            !isPasswordSecure && isPasswordActive ? 'tw-bg-red-500' : '',
          ]"
          class="tw-relative tw-flex tw-justify-center tw-items-center tw-text-white tw-border-0 tw-rounded-lg tw-mr-4 tw-p-2 tw-w-8 tw-h-5 tw-text-md"
        >
          {{ passwordCount }}
        </div>
      </div>
    </div>

    <div
      class="Password__strength-meter tw-relative tw-h-1 tw-bg-gray-350 tw-mt-1 tw-mx-auto tw-rounded-lg"
    >
      <div
        class="Password__strength-meter--fill"
        :data-score="passwordStrength"
      />
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'

export default {
  name: 'VuePasswordStrengthMeter',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    passwordLength: {
      type: Number,
      default: 8,
    },
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      },
    },

    passwordStrength() {
      return this.value ? zxcvbn(this.value, null).score : null
    },

    isPasswordSecure() {
      return this.value ? this.value.length >= this.passwordLength : null
    },

    isPasswordActive() {
      return this.value && this.value.length > 0
    },

    passwordCount() {
      return (
        this.value &&
        (this.value.length > this.passwordLength
          ? `${this.passwordLength}+`
          : this.value.length)
      )
    },
  },
}
</script>

<style lang="scss">
.Password__strength-meter:before,
.Password__strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: 20%;
  z-index: 10;
}

.Password__strength-meter:before {
  left: 20%;
}

.Password__strength-meter:after {
  right: 20%;
}

.Password__strength-meter--fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.Password__strength-meter--fill[data-score='0'] {
  background: darkred;
  width: 20%;
}

.Password__strength-meter--fill[data-score='1'] {
  background: orangered;
  width: 40%;
}

.Password__strength-meter--fill[data-score='2'] {
  background: orange;
  width: 60%;
}

.Password__strength-meter--fill[data-score='3'] {
  background: yellowgreen;
  width: 80%;
}

.Password__strength-meter--fill[data-score='4'] {
  background: green;
  width: 100%;
}
</style>
